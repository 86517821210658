import React, { useMemo } from 'react';
import ProgressBar from '@ramonak/react-progress-bar';
import classNames from 'classnames';
import get from 'lodash/get';
import partition from 'lodash/partition';
import { useSelector } from 'react-redux';
import { DARK } from '@noloco/components/src/constants/surface';
import {
  FLAT_PLANS_V2,
  SEAT_BASED_PLANS,
} from '@noloco/core/src/constants/accountPlanTypes';
import {
  DEPRECATED_FEATURES,
  Feature,
} from '@noloco/core/src/constants/features';
import { billingPlanSelector } from '@noloco/core/src/selectors/billingPlanSelectors';
import { getText } from '@noloco/core/src/utils/lang';
import { usePlanFeatures } from '../utils/hooks/usePlanFeatures';
import UsageHighlight from './UsageHighlight';

const LANG_KEY = 'features.usage';

const Usage = ({ className, filterFeatures, surface, usage }: any) => {
  const billingPlan = useSelector(billingPlanSelector);
  const features = useMemo(
    () =>
      usage
        ? Object.keys(usage).filter((feature) => {
            if (
              feature === '__typename' ||
              // Because of how we override the Redux state it's possible after
              // deprecating a feature and removing it from client queries that
              // it may still appear in state so we need to filter these out.
              (DEPRECATED_FEATURES.includes(feature) &&
                SEAT_BASED_PLANS.includes(billingPlan.type))
            ) {
              console.log();

              return false;
            }

            if (filterFeatures) {
              return filterFeatures(feature);
            }

            return true;
          })
        : [],
    [billingPlan.type, filterFeatures, usage],
  );

  const planConfig = usePlanFeatures();

  const [unlimitedFeatures, limitedFeatures] = partition(
    features,
    (feature) => {
      const planLimit = get(planConfig, feature);

      return typeof planLimit !== 'number';
    },
  );

  const filteredUnlimitedFeatures = useMemo(() => {
    if (FLAT_PLANS_V2.includes(billingPlan.type)) {
      return [];
    }

    return unlimitedFeatures;
  }, [billingPlan.type, unlimitedFeatures]);

  return (
    <div className={className}>
      {filteredUnlimitedFeatures.length > 0 && (
        <div className="mt- mb-6 grid grid-cols-3 gap-2">
          {filteredUnlimitedFeatures.map((feature) => (
            <UsageHighlight
              feature={feature as Feature}
              surface={surface}
              value={get(usage, feature, 0)}
            />
          ))}
        </div>
      )}
      {limitedFeatures.map((feature) => {
        const currentUsage = get(usage, feature, 0);
        const planLimit = get(planConfig, feature);

        const completed = (100 * currentUsage) / planLimit;

        const roundedCurrentUsage = Math.round(currentUsage);
        const roundedPlanLimit = Math.round(planLimit);

        return (
          <div className="mb-4 flex" key={feature}>
            <div className="w-1/3">
              {getText(LANG_KEY, feature, 'title')}
              <div
                className={classNames('text-sm', {
                  'text-gray-600': surface !== DARK,
                  'text-gray-400': surface === DARK,
                })}
              >
                {getText(
                  {
                    current: roundedCurrentUsage,
                    limit: roundedPlanLimit,
                  },
                  LANG_KEY,
                  feature,
                  'usage',
                )}
              </div>
            </div>
            <div className="my-auto w-2/3 pl-16">
              <ProgressBar
                animateOnRender={true}
                bgColor={surface !== DARK ? '#ec4899' : '#14b8a6'}
                completed={Math.round(completed)}
                labelAlignment="right"
                labelColor={
                  completed < 5
                    ? surface !== DARK
                      ? '#94a3b8'
                      : '#9ca3af'
                    : '#fff'
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Usage;
