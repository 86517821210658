import { DateTime, Duration } from 'luxon';
import shortid from 'shortid';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
  MULTIPLE_OPTION,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  CURRENCY,
  EMAIL,
  IP_ADDRESS,
  RATING,
  SINGLE_LINE_TEXT,
  UNFORMATTED_NUMBER,
  URL,
} from '@noloco/core/src/constants/fieldFormats';
import { IMAGE } from '@noloco/core/src/constants/fileTypes';
import { IMAGE_SVG } from '@noloco/core/src/constants/mimetypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { BaseRecord, RecordValue } from '@noloco/core/src/models/Record';
import { durationToString } from '@noloco/core/src/utils/durations';
import { isMultiField } from '@noloco/core/src/utils/relationships';

const EXAMPLE_EMAIL = 'example@example.com';
const EXAMPLE_IP_ADDRESS = '192.168.123.132';
const EXAMPLE_URL = 'https://www.google.com';

const LOREM_IPSUM = 'Lorem ipsum dolor sit amet';

const LOREM_IPSUM_LONG = `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Suspendisse viverra nibh eget est egestas, sed laoreet leo venenatis. Ut aliquet pharetra risus, ac fringilla odio fringilla vestibulum.`;

export const getSampleFieldValue = (
  field: DataField,
  index = 0,
): RecordValue => {
  if (field.name === 'id') {
    return index + 1;
  }

  if (field.name === 'uuid') {
    return shortid.generate();
  }

  if (field.rollup) {
    return 1000 + index;
  }

  switch (field.type) {
    case TEXT: {
      if (field.typeOptions?.format === EMAIL) {
        return EXAMPLE_EMAIL;
      }

      if (field.typeOptions?.format === SINGLE_LINE_TEXT) {
        return LOREM_IPSUM;
      }

      if (field.typeOptions?.format === URL) {
        return EXAMPLE_URL;
      }

      if (field.typeOptions?.format === IP_ADDRESS) {
        return EXAMPLE_IP_ADDRESS;
      }

      return LOREM_IPSUM_LONG;
    }
    case DATE: {
      return DateTime.now().minus({ days: index }).startOf('day').toISO();
    }
    case INTEGER: {
      if (field.typeOptions?.format === RATING) {
        return (index + 1) % 5;
      }

      if (field.typeOptions?.format === CURRENCY) {
        return 1750 + index * 300;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return 452400 * index;
      }

      return 500 + index * 275;
    }
    case DECIMAL: {
      if (field.typeOptions?.format === CURRENCY) {
        return 1750.5 + index * 305.75;
      }

      if (field.typeOptions?.format === UNFORMATTED_NUMBER) {
        return 452410.25 * index;
      }

      return (0.1 + 0.15 * index) % 1;
    }
    case DURATION:
      return durationToString(
        Duration.fromDurationLike({
          hour: 4 + index,
          minutes: 10 + index * 5,
        }),
      );
    case BOOLEAN:
      return index % 2 === 0;
    case SINGLE_OPTION: {
      if (field.options) {
        return field.options[index % field.options?.length].name;
      }

      return null;
    }
    case MULTIPLE_OPTION: {
      if (field.options) {
        return field.options
          .slice(0, index % field.options.length)
          .map((option) => option.name);
      }

      return [];
    }
    case 'user': {
      const user = {
        id: index + 1,
        uuid: shortid.generate(),
        createdAt: new Date().toISOString(),
        firstName: 'User',
        lastName: `#${index + 101}`,
        email: `example-${index + 101}@noloco.io`,
      } as BaseRecord;

      if (isMultiField(field)) {
        return { edges: [{ node: user }] };
      }

      return user;
    }
    case 'file': {
      const file = {
        id: index + 1,
        uuid: shortid.generate(),
        createdAt: new Date().toISOString(),
        url: 'https://media.noloco.app/65-placeholder.svg',
        fileType: IMAGE,
        mimetype: IMAGE_SVG,
      } as BaseRecord;

      if (isMultiField(field)) {
        return { edges: [{ node: file }] };
      }

      return file;
    }
    default:
      return undefined;
  }
};
