import classNames from 'classnames';
import Avatar from '@noloco/core/src/components/Avatar';
import NolocoIcon from '@noloco/core/src/components/NolocoIcon';
import { File } from '@noloco/core/src/models/File';
import { BaseUser } from '@noloco/core/src/models/User';
import { getText } from '@noloco/core/src/utils/lang';

interface TemplateCreatorProps {
  className?: string;
  creator?: BaseUser & { profilePicture: File };
}

const TemplateCreator = ({ className, creator }: TemplateCreatorProps) => (
  <div className={classNames('flex w-full items-center space-x-2', className)}>
    {creator ? (
      <>
        <Avatar size={10} user={creator} />
        <span className="truncate text-sm text-gray-500">
          {creator.firstName} {creator.lastName}
        </span>
      </>
    ) : (
      <>
        <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-gradient-to-tl from-slate-500 to-slate-700 text-xs font-medium uppercase text-slate-200">
          <NolocoIcon className="h-6 w-6" />
        </div>
        <span className="text-sm text-gray-500">
          {getText('newProject.noloco')}
        </span>
      </>
    )}
  </div>
);

export default TemplateCreator;
