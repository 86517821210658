import React, { useMemo } from 'react';
import { Icon, IconFileImport, IconRefresh } from '@tabler/icons-react';
import { IconTableOptions } from '@tabler/icons-react';
import { RadioGroup } from '@noloco/components';
import { OnboardingDataSourceChoice as OnboardingDataSource } from '@noloco/core/src/constants/dataSources';
import { getText } from '@noloco/core/src/utils/lang';
import OnboardingChoiceItem from './OnboardingChoiceItem';

export const CHOICES: Record<OnboardingDataSource, Icon> = {
  external: IconRefresh,
  csv: IconFileImport,
  noloco: IconTableOptions,
};

const LANG_KEY = 'appAuth.onboarding.questions.dataSource.options';

interface OnboardingDataSourceChoiceProps {
  onChange: (sourceType: OnboardingDataSource) => void;
  value: OnboardingDataSource | undefined;
}

const OnboardingDataSourceChoice = ({
  onChange,
  value,
}: OnboardingDataSourceChoiceProps) => {
  const options = useMemo(
    () =>
      Object.keys(CHOICES).map((dataSourceType) => ({
        value: dataSourceType,
        label: ({ checked }: { checked: boolean }) => (
          <OnboardingChoiceItem
            checked={checked}
            title={getText(LANG_KEY, dataSourceType, 'title')}
            subtitle={getText(LANG_KEY, dataSourceType, 'subtitle')}
            ChoiceIcon={CHOICES[dataSourceType as OnboardingDataSource]}
          />
        ),
      })),
    [],
  );

  return (
    <RadioGroup
      className="mt-4 grid grid-cols-1 gap-3"
      onChange={onChange}
      value={value}
      options={options}
    />
  );
};

export default OnboardingDataSourceChoice;
