import React, { useMemo } from 'react';
import {
  Icon,
  IconAddressBook,
  IconArrowNarrowRight,
  IconBuildingWarehouse,
  IconFileDescription,
  IconHomeCheck,
  IconLayoutKanban,
  IconUsers,
} from '@tabler/icons-react';
import { Button, RadioGroup, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';
import OnboardingChoiceItem from './OnboardingChoiceItem';

export type AppChoice =
  | 'salesCrm'
  | 'inventoryManagement'
  | 'clientPortal'
  | 'projectManagement'
  | 'applicantTrackingSystem'
  | 'realEstateManagement';

export type AppChoiceOrOther = AppChoice | 'other';

export const CHOICES: Record<AppChoice, Icon> = {
  salesCrm: IconAddressBook,
  inventoryManagement: IconBuildingWarehouse,
  clientPortal: IconUsers,
  projectManagement: IconLayoutKanban,
  applicantTrackingSystem: IconFileDescription,
  realEstateManagement: IconHomeCheck,
};

const LANG_KEY = 'appAuth.onboarding.questions.goal.options';

interface OnboardingAppTypeChoiceProps {
  appDescription: string;
  onChange: (appType: AppChoiceOrOther) => void;
  onChangeDescription: (description: string) => void;
  onNext: () => void;
  value: AppChoiceOrOther | undefined;
}

const OnboardingAppTypeChoice = ({
  appDescription,
  onChange,
  onChangeDescription,
  onNext,
  value,
}: OnboardingAppTypeChoiceProps) => {
  const options = useMemo(
    () =>
      Object.keys(CHOICES).map((appType) => ({
        value: appType,
        label: ({ checked }: { checked: boolean }) => (
          <OnboardingChoiceItem
            checked={checked}
            title={getText(LANG_KEY, appType)}
            ChoiceIcon={CHOICES[appType as AppChoice]}
          />
        ),
      })),
    [],
  );

  return (
    <div className="flex flex-col">
      <RadioGroup
        className="mt-4 grid grid-cols-2 gap-3"
        onChange={onChange}
        value={value}
        options={options}
      />
      <Button
        onClick={() => onChange('other')}
        className="mr-auto mt-8 flex items-center"
        type="text"
      >
        <span>{getText(LANG_KEY, 'other.label')}</span>
        <IconArrowNarrowRight className="ml-4" size={20} />
      </Button>
      {value === 'other' && (
        <div className="mt-4 flex flex-col pl-3">
          <p className="text-sm text-gray-500">
            {getText(LANG_KEY, 'other.subtitle')}
          </p>
          <div className="mt-2 flex items-center space-x-4">
            <TextInput
              className="w-full"
              placeholder={getText(LANG_KEY, 'other.placeholder')}
              value={appDescription}
              onChange={({ target: { value: nextValue } }) =>
                onChangeDescription(nextValue)
              }
              surface={LIGHT}
            />
            <Button
              onClick={() => onNext()}
              disabled={!appDescription || appDescription.length < 20}
              className="mr-auto flex items-center disabled:opacity-50"
            >
              <span>{getText(LANG_KEY, 'other.next')}</span>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingAppTypeChoice;
