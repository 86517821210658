import React, { memo } from 'react';
import classNames from 'classnames';
import round from 'lodash/round';
import { Surface } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { Feature } from '@noloco/core/src/constants/features';
import { USAGE_FEATURES_WITH_UNITS } from '@noloco/core/src/constants/usage';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'features.usage';

interface Props {
  feature: Feature;
  surface?: Surface;
  value: number;
}

const UsageHighlight = memo(({ feature, surface, value }: Props) => (
  <div
    className={classNames('flex w-full flex-col rounded-lg p-3', {
      'border shadow': surface !== DARK,
      'bg-slate-700': surface === DARK,
    })}
  >
    <span
      className={classNames('text-sm', {
        'text-gray-600': surface !== DARK,
        'text-gray-400': surface === DARK,
      })}
    >
      {getText(LANG_KEY, feature, 'title')}
    </span>
    <span
      className={classNames('text-xl font-medium tracking-wider', {
        'text-black': surface !== DARK,
        'text-white': surface === DARK,
      })}
    >
      {round(value, 2)}
      {USAGE_FEATURES_WITH_UNITS.includes(feature) && (
        <span
          className={classNames('ml-1', {
            'text-gray-500': surface !== DARK,
            'text-gray-400': surface === DARK,
          })}
        >
          {getText(LANG_KEY, feature, 'units')}
        </span>
      )}
    </span>
  </div>
));

export default UsageHighlight;
