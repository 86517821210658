import React from 'react';
import Billing from './Billing';
import WorkspaceList from './WorkspaceList';

const WorkspaceBilling = () => (
  <div className="flex">
    <WorkspaceList path="/billing" redirectToFirstWorkspace={true} />
    <Billing />
  </div>
);

export default WorkspaceBilling;
