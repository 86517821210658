import React from 'react';
import { IconComet } from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge } from '@noloco/components';
import {
  CSV,
  DataSourceType,
  INTERNAL,
  MYSQL,
  OnboardingDataSourceChoice,
  POSTGRES,
} from '@noloco/core/src/constants/dataSources';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'newProject';

export interface DataSourceConfigItem {
  LogoComponent?: any;
  logo?: any;
  langKey: string;
  to?: string;
  guide?: string;
  disabled?: boolean;
  beta?: boolean;
}

interface Props {
  dataSource: DataSourceType | 'CSV';
  dataSourceChoice: OnboardingDataSourceChoice | undefined;
  handleChooseDataSource: (
    event: React.SyntheticEvent,
    dataSource: DataSourceType | 'CSV',
  ) => void;
  dataSourceConfig: Record<string, DataSourceConfigItem>;
  selectedDisabledDataSource: boolean;
}

const ProjectDataSource = ({
  dataSourceChoice,
  dataSource,
  handleChooseDataSource,
  dataSourceConfig,
  selectedDisabledDataSource,
}: Props) => (
  <div className="pb-16">
    <h1 className="mb-3 text-lg text-gray-400">
      {getText(LANG_KEY, 'DATA_SOURCE_SUBTITLE')}
    </h1>
    <div className="relative mt-6 grid w-full grid-cols-2 gap-4 md:grid-cols-1">
      {Object.entries(dataSourceConfig)
        .filter(
          ([dataSourceOption]) =>
            !dataSourceChoice || ![CSV, INTERNAL].includes(dataSourceOption),
        )
        .map(
          ([
            dataSourceOption,
            { disabled, beta, LogoComponent, logo },
          ]: any) => (
            <button
              onClick={(event) =>
                handleChooseDataSource(event, dataSourceOption)
              }
              key={dataSourceOption}
              disabled={selectedDisabledDataSource && disabled}
              className={classNames(
                'relative flex h-full w-full items-center overflow-hidden rounded-lg border bg-white p-6 disabled:opacity-50',
                {
                  'ring ring-pink-500': dataSource === dataSourceOption,
                  'ring-pink-300 hover:ring':
                    dataSource !== dataSourceOption &&
                    (!selectedDisabledDataSource || !disabled),
                },
              )}
            >
              <div className="flex w-10 flex-shrink-0 items-center">
                {LogoComponent ? (
                  <LogoComponent className="mx-auto my-auto mb-auto h-8 w-auto text-black" />
                ) : (
                  <img
                    src={logo}
                    alt={dataSourceOption}
                    className="h-10 flex-shrink-0"
                  />
                )}
              </div>
              <div className="ml-6 flex flex-col text-left">
                <p className="text-base font-medium tracking-wider">
                  {getText('leftSidebar.data.sources', dataSourceOption)}
                </p>
                <p className="text-sm tracking-wider text-gray-600">
                  {getText(LANG_KEY, 'connect', dataSourceOption)}
                </p>
              </div>
              {dataSourceOption === INTERNAL && (
                <Badge color="cyan" className="absolute right-2 top-2">
                  {getText(LANG_KEY, 'connect.recommended')}
                </Badge>
              )}
              {[MYSQL, POSTGRES].includes(dataSourceOption) && (
                <Badge color="pink" className="absolute right-2 top-2">
                  <span className="flex space-x-1">
                    <IconComet size={14} />
                    <span>{getText(LANG_KEY, 'connect.businessPlan')}</span>
                  </span>
                </Badge>
              )}
              {beta && (
                <Badge color="blue" className="absolute right-2 top-2">
                  {getText('leftSidebar.data', 'beta')}
                </Badge>
              )}
            </button>
          ),
        )}
    </div>
    <div className="flex items-center justify-center">
      {selectedDisabledDataSource && (
        <div className="my-4 flex w-full flex-col justify-between rounded-lg bg-gray-700 p-4 text-white">
          <h3 className="text-lg">
            {getText('newProject.dataStep.disabled.title')}
          </h3>
          <p className="text-base text-gray-400">
            {getText('newProject.dataStep.disabled.subtitle')}
          </p>
        </div>
      )}
    </div>
  </div>
);

export default ProjectDataSource;
