import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import { Loader, Modal } from '@noloco/components';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { PROJECT_CREATED, trackEvent } from '@noloco/core/src/utils/analytics';
import {
  getTextFromError,
  useErrorAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectNamePlaceholder } from '@noloco/core/src/utils/newProject';
import { CLONE_PROJECT } from '../queries/project';
import useNewProjectName from '../utils/hooks/useNewProjectName';
import { redirectToProject } from '../utils/project';
import ProjectNameField from './ProjectNameField';
import ProjectWorkspaceSelect from './ProjectWorkspaceSelect';

const LANG_KEY = 'appAuth.onboarding';

interface OnboardingQuickTemplateCloneModalProps {
  onClose: () => void;
  templateName: string;
}

const OnboardingQuickTemplateCloneModal = ({
  onClose,
  templateName,
}: OnboardingQuickTemplateCloneModalProps) => {
  const { user } = useDashboardAuth();
  const firstName = useMemo(
    () => get(user, 'firstName', '').toLowerCase().replace(/\s/g, '-'),
    [user],
  );

  const projectNamePlaceholder = useMemo(
    () => getProjectNamePlaceholder(get(user, 'email', ''), firstName),
    [firstName, user],
  );

  const {
    isNameUnique,
    isNameValid,
    loadingNameCheck,
    onChangeName,
    projectName,
  } = useNewProjectName(projectNamePlaceholder);
  const [createProject] = useMutation(CLONE_PROJECT);

  const [workspaceId, setWorkspaceId] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState(false);
  const errorAlert = useErrorAlert();

  const handleError = useCallback(
    (error: any) => {
      setLoading(false);
      const errorText = error && getTextFromError(error);
      errorAlert(
        errorText ? errorText.title : getText('newProject.errors.generic'),
      );
      setLoading(false);
    },
    [errorAlert],
  );

  const onCreateTemplateClone = useCallback(async () => {
    setLoading(true);
    const options = {
      variables: {
        teamId: parseInt(workspaceId!, 10),
        name: projectName,
        baseName: templateName,
        dataSourceConnection: {},
        dataSourceType: INTERNAL,
      },
    };

    try {
      const { data } = await createProject(options);
      const clonedProject = data.cloneProject;

      if (clonedProject) {
        trackEvent(PROJECT_CREATED, 'name', projectName);

        return redirectToProject(clonedProject.name);
      }
    } catch (e) {
      handleError(e);
      console.log(e);
    }
  }, [createProject, handleError, projectName, templateName, workspaceId]);

  return (
    <Modal
      title={getText(LANG_KEY, 'cloneTemplate', loading ? 'loading' : 'title')}
      confirmText={getText(LANG_KEY, 'cloneTemplate.confirm')}
      confirmDisabled={!isNameUnique || !isNameValid || loading || !workspaceId}
      onConfirm={onCreateTemplateClone}
      onClose={onClose}
      onCancel={onClose}
    >
      {loading && (
        <div className="flex flex-col items-center justify-center p-6">
          <Loader />
        </div>
      )}
      {!loading && (
        <div className="mx-auto w-full max-w-screen-md">
          <ProjectNameField
            isNameValid={isNameValid}
            isNameUnique={isNameUnique}
            loadingNameCheck={loadingNameCheck}
            onChangeName={onChangeName}
            placeholder=""
            projectName={projectName}
          />
          <ProjectWorkspaceSelect
            className="mt-4"
            onChange={setWorkspaceId}
            value={workspaceId}
          />
        </div>
      )}
    </Modal>
  );
};

export default OnboardingQuickTemplateCloneModal;
