import React, { useState } from 'react';
import SplitLayout from '@noloco/components/src/components/auth/SplitLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import FormField from '@noloco/components/src/components/form/FormField';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import { getText } from '@noloco/core/src/utils/lang';
import logoBlack from '../img/infinity-logo-black.svg';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import SidePanel from './SidePanel';

const ForgotPassword = () => {
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const { recoverPassword } = useDashboardAuth();

  useTrackDashboardPage(PageTypes.FORGOT_PASSWORD);

  const handleLogin = (event: any) => {
    event.preventDefault();
    setErrors([]);

    try {
      recoverPassword(email)
        .then(() => {
          setSubmitted(true);
        })
        .catch((error: any) => {
          if (error.graphQLErrors) {
            setErrors(error.graphQLErrors.map((er: any) => er.message));
          } else {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
            setErrors(['Something went wrong, please try again']);
          }
          console.warn('ERROR', error);
        });
    } catch (error) {
      // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
      setErrors([(error as any).message]);
    }
  };

  return (
    <SplitLayout
      errors={errors}
      logoUrl={logoBlack}
      onSubmit={handleLogin}
      titleText={getText('appAuth.forgotPassword.title')}
      subTitleText={getText('appAuth.forgotPassword.help')}
      splitChildren={<SidePanel />}
    >
      {!submitted && (
        <>
          <FormField
            aria-label="email"
            autoComplete="email"
            name="email"
            type="text"
            onChange={({ target: { value } }: any) => setEmail(value)}
            required
            errorType="below-solid"
            label={getText('appAuth.forgotPassword.email.label')}
            placeholder={getText('appAuth.forgotPassword.email.placeholder')}
            value={email}
            surface={LIGHT}
          />
          <SubmitButton disabled={!email || submitted}>
            {getText('appAuth.forgotPassword.confirm')}
          </SubmitButton>
        </>
      )}
      {submitted && (
        <p className="my-3">{getText('appAuth.forgotPassword.submitted')}</p>
      )}
    </SplitLayout>
  );
};

export default ForgotPassword;
