import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconExternalLink } from '@tabler/icons-react';
import classNames from 'classnames';
import { Button, Loader } from '@noloco/components';
import { Variant } from '@noloco/components/src/constants/variants';
import {
  AccountPlan,
  ENTERPRISE,
  EXPERT,
} from '@noloco/core/src/constants/accountPlans';
import { Team } from '@noloco/core/src/models/Team';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { OPEN_CUSTOMER_PORTAL_QUERY } from '../../queries/billing';

interface OpenCustomerPortalButtonProps {
  className?: string;
  plan: AccountPlan;
  workspace: Team;
  variant?: Variant;
}

const OpenCustomerPortalButton = ({
  className,
  plan,
  workspace,
  variant,
}: OpenCustomerPortalButtonProps) => {
  const [loading, setLoading] = useState(false);
  const errorAlert = useGraphQlErrorAlert();
  const [openCustomerPortalSession] = useMutation(
    OPEN_CUSTOMER_PORTAL_QUERY,
    {},
  );

  const onOpenStripeCustomerPortal = useCallback(() => {
    setLoading(true);
    openCustomerPortalSession({
      variables: {
        teamId: parseInt(workspace.id, 10),
        returnUrl: document.location.href,
      },
    })
      .then((mutationData) => {
        const session = mutationData.data.openCustomerPortal;
        document.location = session.portalUrl;
      })
      .catch((e) => {
        setLoading(false);
        errorAlert(getText('billing.customerPortal.error'), e);
      });
  }, [openCustomerPortalSession, workspace.id, errorAlert]);

  const disabled = plan === ENTERPRISE || plan === EXPERT;

  if (disabled) {
    return null;
  }

  return (
    <Button
      className={classNames(className, 'flex items-center')}
      onClick={onOpenStripeCustomerPortal}
      variant={variant}
    >
      {!loading ? (
        <>
          <span> {getText('billing.customerPortal.open')}</span>
          <IconExternalLink className="ml-2 opacity-75" />
        </>
      ) : (
        <Loader size="sm" />
      )}
    </Button>
  );
};

export default OpenCustomerPortalButton;
