import React from 'react';
import { IconAlertCircle, IconCheck } from '@tabler/icons-react';
import { FormField, Loader, Tooltip } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';
import { getBaseProjectDomain } from '@noloco/core/src/utils/pages';

interface Props {
  isNameUnique: boolean;
  isNameValid: boolean;
  label?: string;
  loadingNameCheck: boolean;
  onChangeName: (name: string) => void;
  placeholder: string;
  error?: string;
  projectName: string;
}

const ProjectNameField = ({
  isNameUnique,
  isNameValid,
  label,
  loadingNameCheck,
  onChangeName,
  placeholder,
  projectName,
}: Props) => (
  <div className="flex flex-col">
    <div className="flex">
      <div className="w-full">
        <div className="my-1.5 flex items-end">
          <FormField
            className="flex-grow text-lg"
            p={{ x: 6, y: 3 }}
            rounded="lg"
            label={label}
            placeholder={placeholder}
            value={projectName}
            onChange={onChangeName}
            surface={LIGHT}
            autoFocus={true}
          />
          <div className="ml-3 mt-2 flex items-center justify-between text-2xl">
            <p className="mr-4">.{getBaseProjectDomain()}</p>
            {!loadingNameCheck &&
              (isNameUnique ? (
                <IconCheck size={20} className="text-cyan-500" />
              ) : (
                <Tooltip
                  content={
                    <div className="flex flex-col text-left">
                      <p>
                        <span className="font-bold">
                          {getText('newProject.name.invalid.description')}
                        </span>
                        <ul className="mt-1 list-disc pl-6">
                          {(!projectName || projectName.length <= 3) && (
                            <li>{getText('newProject.name.invalid.length')}</li>
                          )}
                          {projectName && /^[0-9]/.test(projectName) && (
                            <li>{getText('newProject.name.invalid.start')}</li>
                          )}
                          {!isNameValid && (
                            <li>
                              {getText('newProject.name.invalid.characters')}
                            </li>
                          )}
                          {!isNameUnique && (
                            <li>{getText('newProject.name.invalid.unique')}</li>
                          )}
                        </ul>
                      </p>
                    </div>
                  }
                  bg="white"
                >
                  <span>
                    <IconAlertCircle size={20} className="text-red-400" />
                  </span>
                </Tooltip>
              ))}
            {loadingNameCheck && <Loader size="sm" />}
          </div>
        </div>
        <p className="my-3 text-sm text-gray-400">
          {getText('newProject.name.help')}
        </p>
      </div>
    </div>
  </div>
);

export default ProjectNameField;
