import React from 'react';

interface Props {
  button?: React.ReactNode;
  children: React.ReactNode;
  title: string;
}

const PlanConfigurationSection = ({ button, children, title }: Props) => (
  <div className="w-full space-y-4">
    <div className="text-md mb-4 flex flex-row font-medium">
      <h2>{title}</h2>
      {button && <div className="ml-auto">{button}</div>}
    </div>
    {children}
  </div>
);

export default PlanConfigurationSection;
