import { Usage } from '@noloco/core/src/models/Usage';

export interface TeamUsage {
  id: number;
  name: string;
  plan: {
    id: number;
    usage: Usage;
  };
}

export const teamsUsageSelector = (state: { teamsUsage: TeamUsage[] }) =>
  state.teamsUsage;
