import React, { memo } from 'react';
import { IconExternalLink } from '@tabler/icons-react';
import classNames from 'classnames';
import { Surface } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';

interface Props {
  className?: string;
  surface?: Surface;
}

const PlansHelpLink = memo(({ className, surface }: Props) => (
  <a
    className={classNames(className, 'flex items-center font-medium', {
      'text-pink-500 hover:text-pink-400': surface !== DARK,
      'text-cyan-500 hover:text-cyan-400': surface === DARK,
    })}
    href="https://noloco.io/pricing"
    target="_blank"
    rel="noreferrer noopener"
  >
    <span className="mr-1"> {getText('billing.plan.help')}</span>
    <IconExternalLink size={14} />
  </a>
));

export default PlansHelpLink;
