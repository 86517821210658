import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import queryString from 'query-string';
import { Redirect } from 'react-router';
import { Button, Loader, RadioGroup, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import NolocoIcon from '@noloco/core/src/components/NolocoWordmark';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import { OnboardingDataSourceChoice as OnboardingDataSource } from '@noloco/core/src/constants/dataSources';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { IS_PRODUCTION } from '../constants/env';
import { ONBOARDING_MUTATION } from '../queries/onboarding';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';
import OnboardingAppTypeChoice, {
  AppChoice,
  AppChoiceOrOther,
} from './OnboardingAppTypeChoice';
import OnboardingDataSourceChoice from './OnboardingDataSourceChoice';
import OnboardingExpertQuestion from './OnboardingExpertQuestion';
import OnboardingProgressBar from './OnboardingProgressBar';
import OnboardingQuickTemplateCloneModal from './OnboardingQuickTemplateCloneModal';

const LANG_KEY = 'appAuth.onboarding';
const OTHER = 'other';

const getTemplateForGoal = (goal: AppChoice) => {
  if (IS_PRODUCTION) {
    return {
      salesCrm: 'noloco-salescrm-template',
      inventoryManagement: 'inventorymgmt-nlc-template',
      clientPortal: 'client-portal-nlc-template-v2',
      projectManagement: 'noloco-projmgmt-template',
      applicantTrackingSystem: 'atstemplate-nlc',
      realEstateManagement: 'realestate-template-nlc',
    }[goal];
  }

  return 'portal';
};

type ONBOARDING_STEP =
  | 'team'
  | 'companySize'
  | 'industry'
  | 'goal'
  | 'connectWithExpert'
  | 'dataSource';

const ONBOARDING_QUESTIONS: Record<string, ONBOARDING_STEP> = {
  TEAM: 'team',
  COMPANY_SIZE: 'companySize',
  INDUSTRY: 'industry',
  GOAL: 'goal',
  CONNECT_WITH_EXPERT: 'connectWithExpert',
  DATA_SOURCE: 'dataSource',
};

const steps = {
  [ONBOARDING_QUESTIONS.TEAM]: [
    'founder',
    'engineering',
    'productManagement',
    'finance',
    'customerSupport',
    'operations',
    'humanResources',
    'salesAndMarketing',
    OTHER,
  ],
  [ONBOARDING_QUESTIONS.COMPANY_SIZE]: [
    'lessThan10',
    'lessThan25',
    'lessThan50',
    'lessThan100',
    'moreThan100',
  ],
  [ONBOARDING_QUESTIONS.INDUSTRY]: [
    'agriculture',
    'automotive',
    'banking',
    'construction',
    'education',
    'energy',
    'entertainment',
    'foodAndBeverage',
    'healthcare',
    'hospitality',
    'insurance',
    'manufacturing',
    'media',
    'nonProfit',
    'pharmaceutical',
    'realEstate',
    'retail',
    'softwareAndTechnology',
    'telecommunications',
    'transportation',
    'travelAndTourism',
    'utilities',
    'wholesaleTrade',
    'government',
    OTHER,
  ],
  [ONBOARDING_QUESTIONS.GOAL]: [],
  [ONBOARDING_QUESTIONS.CONNECT_WITH_EXPERT]: [],
  [ONBOARDING_QUESTIONS.DATA_SOURCE]: [],
} as Record<ONBOARDING_STEP, string[]>;

const QUESTION_ORDER: ONBOARDING_STEP[] = [
  ONBOARDING_QUESTIONS.TEAM,
  ONBOARDING_QUESTIONS.COMPANY_SIZE,
  ONBOARDING_QUESTIONS.INDUSTRY,
  ONBOARDING_QUESTIONS.GOAL,
  ONBOARDING_QUESTIONS.CONNECT_WITH_EXPERT,
  ONBOARDING_QUESTIONS.DATA_SOURCE,
];

const Onboarding = ({ history }: any) => {
  const { pushQueryParams } = useRouter();
  useTrackDashboardPage(PageTypes.ONBOARDING);
  const [saveOnboardingDetails] = useMutation(ONBOARDING_MUTATION);
  const { user, fetchedUser } = useDashboardAuth();
  const [_, setErrors] = useState([]);
  const [currentStep, setCurrentStep] = useState<ONBOARDING_STEP>(
    QUESTION_ORDER[0],
  );
  const [selectedOptions, setSelectedOptions] = useState<
    Partial<Record<ONBOARDING_STEP, boolean | string>>
  >({});
  const [appDescription, setAppDescription] = useState('');
  const [templateToClone, setTemplateToClone] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const stepValidated = useMemo(
    () => get(selectedOptions, currentStep, undefined) !== undefined,
    [selectedOptions, currentStep],
  );

  const selectOption = useCallback(
    (option: string | boolean) => {
      const newSelectedOptions = set(currentStep, option, selectedOptions);

      setSelectedOptions(newSelectedOptions);
    },
    [selectedOptions, currentStep],
  );

  const currentStepIndex = useMemo(
    () => QUESTION_ORDER.indexOf(currentStep),
    [currentStep],
  );

  const handleSubmit = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault();
      }
      setErrors([]);

      if (currentStepIndex < QUESTION_ORDER.length - 1) {
        pushQueryParams({ step: 'teamSize' });

        return setCurrentStep(QUESTION_ORDER[currentStepIndex + 1]);
      }

      setIsLoading(true);
      const onboardingDetails: any = {
        onboardingCompleted: false,
        appDescription: appDescription ?? undefined,
      };
      QUESTION_ORDER.forEach((questionKey) => {
        const value = get(selectedOptions, questionKey);
        let answer =
          questionKey && get(steps, questionKey).includes(value as string)
            ? getText(
                LANG_KEY,
                'questions',
                questionKey,
                'options',
                value as string,
              )
            : value;

        onboardingDetails[questionKey] = answer;
      });

      try {
        saveOnboardingDetails({
          variables: { onboardingDetails },
        })
          .then(() => {
            const dataSourceType = get(
              selectedOptions,
              ONBOARDING_QUESTIONS.DATA_SOURCE,
            );
            const goal = get(selectedOptions, ONBOARDING_QUESTIONS.GOAL);

            if (dataSourceType === 'noloco' && goal !== 'other') {
              setTemplateToClone(getTemplateForGoal(goal as AppChoice));
            } else {
              const qs = queryString.stringify({
                onboarding: false,
                goal,
                appDescription,
                dataSourceType,
                dataSource: dataSourceType === 'noloco' ? INTERNAL : undefined,
                step: ['csv', 'noloco'].includes(dataSourceType as string)
                  ? 'DETAILS'
                  : undefined,
              });

              return history.push(`/new?${qs}`);
            }
          })
          .catch((error) => {
            if (error.graphQLErrors) {
              setErrors(error.graphQLErrors.map((er: any) => er.message));
            } else {
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'never'.
              setErrors(['Something went wrong, please try again']);
            }
            console.warn('ERROR', error);
            setIsLoading(false);
          });
      } catch (error) {
        // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
        setErrors([(error as any).message]);
        setIsLoading(false);
      }
    },
    [
      appDescription,
      currentStepIndex,
      history,
      pushQueryParams,
      saveOnboardingDetails,
      selectedOptions,
    ],
  );

  const onChooseAppType = useCallback(
    (appChoice: AppChoiceOrOther) => {
      selectOption(appChoice);
    },
    [selectOption],
  );

  const onChooseExpertConnect = useCallback(
    (connectWithExpert: boolean) => {
      selectOption(connectWithExpert);
    },
    [selectOption],
  );

  if (!user && fetchedUser) {
    return <Redirect to="/login?loginRedirect=/onboarding" />;
  }

  return (
    <div className="flex h-screen w-full items-center justify-center bg-white">
      <div
        className={classNames('w-full', {
          'max-w-2xl': currentStep !== ONBOARDING_QUESTIONS.INDUSTRY,
          'max-w-screen-md': currentStep === ONBOARDING_QUESTIONS.INDUSTRY,
        })}
      >
        <OnboardingProgressBar
          value={currentStepIndex}
          max={QUESTION_ORDER.length}
        />
        <NolocoIcon className="mb-16 w-40" />
        {currentStep === QUESTION_ORDER[0] && (
          <h1 className="my-3 text-2xl font-extrabold leading-9 text-gray-900">
            {getText(LANG_KEY, 'title')}
          </h1>
        )}
        <h1 className="mb-1 text-lg font-medium">
          {getText(LANG_KEY, 'questions', currentStep, 'title')}
        </h1>
        {(currentStep === ONBOARDING_QUESTIONS.TEAM ||
          currentStep === ONBOARDING_QUESTIONS.GOAL ||
          currentStep === ONBOARDING_QUESTIONS.DATA_SOURCE) && (
          <h2 className="text-md mb-6 text-gray-400">
            {getText(LANG_KEY, 'questions', currentStep, 'subtitle')}
          </h2>
        )}
        {[
          ONBOARDING_QUESTIONS.TEAM,
          ONBOARDING_QUESTIONS.COMPANY_SIZE,
          ONBOARDING_QUESTIONS.INDUSTRY,
        ].includes(currentStep) && (
          <RadioGroup
            className={classNames('mt-4 grid gap-3', {
              'grid-cols-2': currentStep !== ONBOARDING_QUESTIONS.INDUSTRY,
              'grid-cols-3 md:grid-cols-2':
                currentStep === ONBOARDING_QUESTIONS.INDUSTRY,
            })}
            onChange={selectOption}
            value={get(selectedOptions, currentStep)}
            options={get(steps, currentStep, []).map((step) => ({
              value: step,
              label: ({ value, checked }: any) => {
                const optionLabel = getText(
                  LANG_KEY,
                  'questions',
                  currentStep,
                  'options',
                  value,
                );

                if (value === OTHER) {
                  return null;
                }

                return (
                  <div
                    className={classNames(
                      'flex min-h-10 w-full cursor-pointer items-center rounded-lg border-2 border-transparent bg-gray-100 p-4 text-sm font-medium leading-5 transition duration-150 ease-in-out hover:bg-gray-200 focus:outline-none',
                      {
                        'bg-gray-200 ring ring-pink-300': checked,
                      },
                    )}
                  >
                    {optionLabel}
                  </div>
                );
              },
            }))}
          />
        )}
        {[
          ONBOARDING_QUESTIONS.TEAM,
          ONBOARDING_QUESTIONS.COMPANY_SIZE,
          ONBOARDING_QUESTIONS.INDUSTRY,
        ].includes(currentStep) &&
          get(steps, currentStep).includes(OTHER) && (
            <TextInput
              aria-label={OTHER}
              inline={true}
              border={false}
              className={classNames(
                'mb-3 flex w-full cursor-pointer items-center justify-center rounded-lg border-2 border-transparent bg-gray-100 px-4 py-1.5 text-sm font-medium leading-5 placeholder-gray-400 outline-none ring-pink-300 transition duration-150 ease-in-out hover:bg-gray-200 focus:placeholder-gray-400 focus:outline-none focus:ring',
                {
                  'bg-gray-200 text-black ring ring-pink-300':
                    get(selectedOptions, currentStep) === OTHER,
                },
              )}
              placeholder={getText(
                LANG_KEY,
                'questions',
                currentStep,
                'options',
                OTHER,
              )}
              surface={LIGHT}
              name={OTHER}
              value={
                get(steps, currentStep).includes(
                  get(selectedOptions, currentStep) as string,
                )
                  ? ''
                  : (get(selectedOptions, currentStep) as string | undefined)
              }
              onChange={({ target: { value } }: any) =>
                selectOption(value.trim())
              }
            />
          )}
        {currentStep === ONBOARDING_QUESTIONS.GOAL && (
          <OnboardingAppTypeChoice
            appDescription={appDescription}
            onChange={onChooseAppType}
            onChangeDescription={setAppDescription}
            onNext={handleSubmit}
            value={
              get(selectedOptions, ONBOARDING_QUESTIONS.GOAL) as
                | AppChoiceOrOther
                | undefined
            }
          />
        )}
        {currentStep === ONBOARDING_QUESTIONS.CONNECT_WITH_EXPERT && (
          <OnboardingExpertQuestion
            onChange={onChooseExpertConnect}
            value={
              get(selectedOptions, ONBOARDING_QUESTIONS.CONNECT_WITH_EXPERT) as
                | boolean
                | undefined
            }
          />
        )}
        {currentStep === ONBOARDING_QUESTIONS.DATA_SOURCE && (
          <OnboardingDataSourceChoice
            onChange={selectOption}
            value={
              get(selectedOptions, ONBOARDING_QUESTIONS.DATA_SOURCE) as
                | OnboardingDataSource
                | undefined
            }
          />
        )}
        {(currentStep !== ONBOARDING_QUESTIONS.INDUSTRY ||
          get(selectedOptions, ONBOARDING_QUESTIONS.INDUSTRY) !== 'other') && (
          <Button
            disabled={!stepValidated}
            onClick={handleSubmit}
            className="mt-6 flex h-10 w-full items-center justify-center"
          >
            {isLoading ? <Loader size="sm" /> : getText(LANG_KEY, 'continue')}
          </Button>
        )}
      </div>
      {templateToClone && (
        <OnboardingQuickTemplateCloneModal
          templateName={templateToClone}
          onClose={() => {
            setTemplateToClone(null);
            setIsLoading(false);
          }}
        />
      )}
    </div>
  );
};

export default Onboarding;
