import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { Redirect } from 'react-router';
import { Loader } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import { CUSTOM_DOMAIN } from '@noloco/core/src/constants/features';
import { getGhostUserTokenQueryString } from '@noloco/core/src/queries/project';
import { isTrialActive } from '@noloco/core/src/utils/billing';
import { isFeatureEnabledForPlan } from '@noloco/core/src/utils/features';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { getProjectDomain } from '@noloco/core/src/utils/pages';
import { BILLING_PLAN_QUERY } from '../queries/project';
import useTrackDashboardPage, {
  PageTypes,
} from '../utils/hooks/useTrackDashboardPage';

const GHOST_USER_TOKEN_QUERY_STRING = getGhostUserTokenQueryString(null);

const PROJECT_QUERY = gql`
  query project($projectId: String!) {
    project(projectId: $projectId) {
      id
      name
      domains {
        id
        name
        active
      }
      creator {
        id
        createdAt
      }
    }
  }
`;

const getPrimaryProjectDomain = (
  project: any,
  customDomainsAreEnabled: any,
) => {
  if (
    project.domains &&
    project.domains.length > 0 &&
    customDomainsAreEnabled
  ) {
    const domain = project.domains[0];

    if (domain.active) {
      return `https://${domain.name}`;
    }
  }

  return getProjectDomain(project.name);
};

const ProjectAuthRedirect = ({
  pathname,
  project,
  teamPlan,
  __onboardingModal,
}: any) => {
  const { sm: isSmScreen } = useBreakpoints();
  const isValidPlanForCustomDomain = useMemo(
    () =>
      isTrialActive(teamPlan.status) ||
      isFeatureEnabledForPlan(teamPlan, CUSTOM_DOMAIN),
    [teamPlan],
  );

  const { loading, data: ghostUserData } = useQuery(
    gql`
      ${GHOST_USER_TOKEN_QUERY_STRING}
    `,
    {
      context: {
        projectQuery: true,
        withCoreAuth: true,
        projectName: project.name,
      },
    },
  );

  const token = get(ghostUserData, 'ghostUser.token');
  const replaceRegex = new RegExp(`^/p/${project.name}`);
  const newPathname = pathname.replace(replaceRegex, '');

  useEffect(() => {
    if (token) {
      document.location = `${getPrimaryProjectDomain(
        project,
        isValidPlanForCustomDomain,
      )}${newPathname}?authToken=${token}${
        isSmScreen ? '' : '&__buildMode=true'
      }${__onboardingModal === 'true' ? '&__onboardingModal=true' : ''}`;
    }
  }, [
    __onboardingModal,
    isSmScreen,
    isValidPlanForCustomDomain,
    newPathname,
    project,
    token,
  ]);

  if (!loading && !token) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
        <p className="max-w-md text-center">
          {getText('project.errors.generic')}
        </p>
      </div>
    );
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
      <Loader size="lg" className="text-blue-400" />
    </div>
  );
};

const ProjectRedirect = () => {
  const {
    pathname,
    query: { projectId, __onboardingModal = 'false' },
  } = useRouter();

  const { fetchedUser, user } = useDashboardAuth();
  useTrackDashboardPage(PageTypes.PROJECT_REDIRECT, { projectId });

  const { error, data } = useQuery(PROJECT_QUERY, {
    variables: { projectId },
    errorPolicy: 'all',
  });

  const { data: teamPlanData } = useQuery(BILLING_PLAN_QUERY, {
    context: { projectQuery: true, projectName: projectId, errorPolicy: 'all' },
  });

  if (fetchedUser && !user) {
    return <Redirect to="/login" />;
  }

  if (error && !data) {
    return (
      <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
        <p className="max-w-md text-center">
          {getText('project.errors.generic')}
        </p>
      </div>
    );
  }

  if (data && data.project && teamPlanData && teamPlanData.billingPlan) {
    return (
      <ProjectAuthRedirect
        pathname={pathname}
        project={data.project}
        teamPlan={teamPlanData.billingPlan}
        __onboardingModal={__onboardingModal}
      />
    );
  }

  return (
    <div className="flex h-screen w-screen items-center justify-center bg-slate-800 text-white">
      <Loader size="lg" className="text-blue-400" />
    </div>
  );
};

export default ProjectRedirect;
