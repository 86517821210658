import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { TABLE } from '@noloco/core/src/constants/dataSources';
import { MULTIPLE_OPTION } from '@noloco/core/src/constants/dataTypes';
import { ReadOnlyFieldCellValue } from '@noloco/core/src/elements/sections/collections/FieldCell';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import DataTypes from '@noloco/core/src/models/DataTypes';
import {
  CollectionConnection,
  RecordValue,
} from '@noloco/core/src/models/Record';
import { isMultiField } from '@noloco/core/src/utils/relationships';
import DataFieldIcon from '../DataFieldIcon';

interface Props {
  dataTypes: DataTypes;
  field: DataField;
  value: RecordValue;
}

const FieldRow = ({ dataTypes, field, value }: Props) => {
  const emptyProject = useMemo(
    () => ({ elements: [], settings: { flags: { v2: true } }, dataTypes }),
    [dataTypes],
  );

  return (
    <>
      <div className="flex w-1/2 items-center border-r py-2 pr-2 hover:w-full hover:flex-shrink-0 hover:border-0 hover:p-0">
        <DataFieldIcon
          className="w-4 flex-shrink-0 text-gray-800 opacity-75"
          field={field}
        />
        <span className="ml-2 truncate">{field.display}</span>
      </div>
      <div
        className={classNames(
          'max-w-1/2 h-full overflow-hidden truncate pl-2 text-gray-500',
          {
            'py-2':
              field.type !== MULTIPLE_OPTION &&
              (!isMultiField(field) ||
                get(value as CollectionConnection, 'edges', []).length === 0),
            'py-1': field.type === MULTIPLE_OPTION,
          },
        )}
      >
        {value && field.type !== FILE ? (
          <ReadOnlyFieldCellValue
            field={field}
            value={value}
            config={{ name: field.name, truncate: true }}
            layout={TABLE}
            project={emptyProject}
          />
        ) : (
          <span>--</span>
        )}
      </div>
    </>
  );
};

export default FieldRow;
