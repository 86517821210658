import React, { useState } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Button, TextInput } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { getText } from '@noloco/core/src/utils/lang';

interface Props {
  disabled: boolean;
  onApplyPromotionCode: (promotionCode: string | null) => void;
  valid: boolean | null;
}

const PromotionCode = ({ disabled, onApplyPromotionCode, valid }: Props) => {
  const [promotionCode, setPromotionCode] = useState('');

  const appliedOrDisabled = disabled || !isNil(valid);

  return (
    <div className="flex items-center">
      <TextInput
        disabled={appliedOrDisabled}
        value={
          isNil(valid)
            ? promotionCode
            : getText(
                { code: promotionCode },
                'billing.promoCodes',
                valid ? promotionCode : 'default',
              )
        }
        onChange={({ target: { value } }: any) => setPromotionCode(value)}
        placeholder={getText('billing.changePlan.promotionCode.placeholder')}
        className={classNames('mr-2 rounded-lg', {
          'border-green-400': !isNil(valid),
        })}
        surface={LIGHT}
        readOnly={appliedOrDisabled}
      />
      <Button
        disabled={disabled}
        className="rounded-lg"
        variant="secondary"
        onClick={() =>
          onApplyPromotionCode(isNil(valid) ? promotionCode : null)
        }
      >
        {getText(
          'billing.changePlan.promotionCode',
          isNil(valid) ? 'apply' : 'remove',
        )}
      </Button>
    </div>
  );
};

export default PromotionCode;
