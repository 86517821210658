import { createSlice } from '@reduxjs/toolkit';
import { TeamUsage } from '../selectors/teamsUsageSelectors';

const teamsUsageSlice = createSlice({
  name: 'teamsUsage',
  initialState: [] as TeamUsage[],
  reducers: {
    setTeamsUsage: (state, { payload }: { payload: TeamUsage[] }) => payload,
  },
});

export const { setTeamsUsage } = teamsUsageSlice.actions;

export default teamsUsageSlice.reducer;
