import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconPlus, IconSettings } from '@tabler/icons-react';
import classNames from 'classnames';
import first from 'lodash/first';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { FormField, Modal, Tooltip } from '@noloco/components/src';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { EXPERT, FREE } from '@noloco/core/src/constants/accountPlans';
import { addTeamToUser } from '@noloco/core/src/reducers/user';
import { useDashboardAuth } from '@noloco/core/src/utils/hooks/useAuth';
import useRouter from '@noloco/core/src/utils/hooks/useRouter';
import { getText } from '@noloco/core/src/utils/lang';
import { ADD_TEAM } from '../queries/teams';

const LANG_KEY = 'workspaces';

interface WorkspaceListProps {
  className?: string;
  path: string;
  redirectToFirstWorkspace?: boolean;
}

const WorkspaceList = ({
  className,
  path,
  redirectToFirstWorkspace = false,
}: WorkspaceListProps) => {
  const { user } = useDashboardAuth();
  const {
    query: { workspace },
  } = useRouter();
  const dispatch = useDispatch();
  const [addTeam, { loading: teamLoading }] = useMutation(ADD_TEAM);
  const [showAddWorkspaceModal, setShowAddWorkspaceModal] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');

  const hasFreePlan = useMemo(
    () => user?.teams.some((team) => team.plan.type === FREE),
    [user?.teams],
  );

  const hasExpertPlan = useMemo(
    () => user?.teams.some((team) => team.plan.type === EXPERT),
    [user?.teams],
  );

  const saveNewWorkspace = useCallback(() => {
    if (workspaceName) {
      addTeam({ variables: { name: workspaceName } }).then((result) => {
        setWorkspaceName('');
        setShowAddWorkspaceModal(false);
        const newTeam = result.data.addTeam;
        dispatch(addTeamToUser(newTeam));
      });
    }
  }, [addTeam, dispatch, workspaceName]);

  if (redirectToFirstWorkspace) {
    const firstWorkspace = first(user?.teams);

    if (
      firstWorkspace &&
      (!workspace || !user?.teams.find((team) => team.id === workspace))
    ) {
      return <Redirect to={`${path}/${firstWorkspace.id}`} />;
    }
  }

  return (
    <div
      className={classNames(
        'flex w-64 flex-shrink-0 flex-col border-r px-4 py-8 md:w-full md:border-0',
        className,
      )}
    >
      <div className="flex items-center justify-between">
        <Link to={path}>
          <h3 className="text-sm uppercase text-gray-400">
            {getText(LANG_KEY, 'label')}
          </h3>
        </Link>
        <Tooltip
          content={getText(
            LANG_KEY,
            'new.tooltip',
            hasFreePlan && !hasExpertPlan ? 'disabled' : 'enabled',
          )}
        >
          <span>
            <button
              disabled={hasFreePlan && !hasExpertPlan}
              onClick={() => setShowAddWorkspaceModal(true)}
            >
              <IconPlus
                size={16}
                className="text-gray-500 opacity-75 hover:opacity-100"
              />
            </button>
          </span>
        </Tooltip>
      </div>
      <div className="mt-2 flex flex-col gap-y-1">
        {user?.teams.map((team) => (
          <Link
            key={team.id}
            to={`${path}/${team.id}`}
            className={classNames(
              'group/workspace flex cursor-pointer items-center rounded-lg p-2 text-sm',
              {
                'bg-gray-50 text-gray-800': team.id === workspace,
                'text-gray-500 hover:bg-gray-100': team.id !== workspace,
              },
            )}
          >
            <span className="mr-2 truncate">{team.name}</span>
            <Link
              to={`/workspaces/${team.id}`}
              className="ml-auto hidden opacity-50 hover:opacity-100 group-hover/workspace:flex"
            >
              <IconSettings size={16} />
            </Link>
          </Link>
        ))}
      </div>
      {showAddWorkspaceModal && (
        <Modal
          title={getText(LANG_KEY, 'new.title')}
          closeOnOutsideClick={false}
          confirmDisabled={!workspaceName}
          cancelText={getText(LANG_KEY, 'new.cancel')}
          confirmText={getText(LANG_KEY, 'new.confirm')}
          onCancel={() => setShowAddWorkspaceModal(false)}
          onClose={() => setShowAddWorkspaceModal(false)}
          onConfirm={saveNewWorkspace}
          loading={teamLoading}
        >
          <p className="mb-4 text-gray-600">
            {getText(LANG_KEY, 'new.description')}
          </p>
          <FormField
            label={getText(LANG_KEY, 'new.name.label')}
            name="name"
            onChange={({ target: { value } }: any) => setWorkspaceName(value)}
            placeholder={getText(LANG_KEY, 'new.name.placeholder')}
            required
            type="text"
            surface={LIGHT}
            value={workspaceName}
          />
        </Modal>
      )}
    </div>
  );
};

export default WorkspaceList;
