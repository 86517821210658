import React, { memo } from 'react';
import { getText } from '@noloco/core/src/utils/lang';

const LANG_KEY = 'newProject.recommendations';

interface Props {
  onSwitchToManual: () => void;
}

const ManualCollectionBuilderLink = memo(({ onSwitchToManual }: Props) => (
  <div className="mt-6 flex justify-end pr-2 text-sm text-gray-500">
    <span
      className="cursor-pointer hover:text-pink-500"
      onClick={onSwitchToManual}
    >
      {getText(LANG_KEY, 'skip')}
    </span>
  </div>
));

export default ManualCollectionBuilderLink;
