import React, { memo, useMemo } from 'react';
import {
  IconBolt,
  IconCircleCheck,
  IconCirclePlus,
  IconDatabase,
  IconDatabaseImport,
  IconInfinity,
  IconRefresh,
  IconRotate2,
  IconUsers,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { Badge, Surface, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { MONTH } from '@noloco/core/src/constants/accountPlanIntervals';
import {
  CURRENT_PLANS,
  DEPRECATED_PLANS,
  FLAT_PLANS,
  FLAT_PLANS_V2,
  SEAT_BASED_PLANS,
} from '@noloco/core/src/constants/accountPlanTypes';
import {
  AccountPlan,
  BUSINESS,
  BUSINESS_V2,
  ENTERPRISE,
  EXPERT,
  FREE,
  PRO,
  PRO_V2,
  SCALE,
  STARTER_V2,
  TEAM,
} from '@noloco/core/src/constants/accountPlans';
import { BillingPlan } from '@noloco/core/src/models/BillingPlan';
import { getText } from '@noloco/core/src/utils/lang';

interface Props {
  activePlan: AccountPlan | undefined;
  billingPlan: BillingPlan;
  className?: string;
  onSelectActivePlan: (accountPlan: AccountPlan) => void;
  surface?: Surface;
  vertical?: boolean;
}

const SYNCING_ICONS = {
  [FREE]: IconRotate2,
  [TEAM]: IconRefresh,
  [STARTER_V2]: IconRefresh,
  [PRO]: IconRefresh,
  [PRO_V2]: IconRefresh,
  [SCALE]: IconInfinity,
  [BUSINESS]: IconInfinity,
  [BUSINESS_V2]: IconInfinity,
};

// @ts-expect-error TS(2339): Property 'plan' does not exist on type '{ children... Remove this comment to see the full error message
const SyncingIcon = memo(({ plan, ...rest }) => {
  const Icon = SYNCING_ICONS[plan] || IconRotate2;

  return <Icon {...rest} />;
});

const PlanPicker = ({
  activePlan,
  billingPlan,
  className,
  onSelectActivePlan,
  surface,
  vertical,
}: Props) => {
  const plans = useMemo(() => {
    if (billingPlan && DEPRECATED_PLANS.includes(billingPlan.type)) {
      return [billingPlan.type, ...CURRENT_PLANS];
    }

    return CURRENT_PLANS;
  }, [billingPlan]);

  if (activePlan === ENTERPRISE || activePlan === EXPERT) {
    return null;
  }

  return (
    <div
      className={classNames(className, 'gap-2 rounded-lg', {
        'md:flex-col': !vertical,
        'flex flex-col': vertical,
        flex: !vertical && !DEPRECATED_PLANS.includes(billingPlan?.type),
        'grid grid-cols-2 md:flex':
          !vertical && DEPRECATED_PLANS.includes(billingPlan?.type),
      })}
    >
      {plans.map((plan: AccountPlan) => (
        <div
          key={plan}
          className={classNames(
            'relative flex w-full flex-col rounded-lg p-4',
            {
              'bg-pink-200 bg-opacity-25':
                surface !== DARK && plan === activePlan,
              'bg-slate-700': surface === DARK && plan === activePlan,
              'bg-gray-50 hover:bg-slate-300 hover:bg-opacity-25':
                plan !== activePlan,
              'cursor-not-allowed opacity-75': DEPRECATED_PLANS.includes(plan),
              'cursor-pointer': !DEPRECATED_PLANS.includes(plan),
              'bg-slate-800': surface === DARK && plan !== activePlan,
            },
          )}
          onClick={
            DEPRECATED_PLANS.includes(plan)
              ? undefined
              : () => onSelectActivePlan(plan)
          }
        >
          {!vertical && (
            <div
              className={classNames(
                'absolute right-4 top-4 h-5 w-5 rounded-full',
                {
                  'border-4 border-pink-400': plan === activePlan,
                  border: plan !== activePlan,
                },
              )}
            />
          )}
          <div className={classNames('flex', { 'h-36 flex-col': !vertical })}>
            <div className="flex items-center">
              <Tooltip
                content={
                  <span className="mt-2 text-sm text-gray-600">
                    {getText('billing.plans', plan, 'price.explainer')}
                  </span>
                }
                disabled={!vertical}
              >
                <span className="text-base font-medium tracking-wider">
                  {getText('billing.plans', plan, 'name')}
                </span>
              </Tooltip>
              {plan === activePlan && plan !== FREE && billingPlan && (
                <Badge className="ml-2" color="pink">
                  {getText(
                    'billing.plan.interval',
                    billingPlan.interval || MONTH,
                    'label',
                  )}
                </Badge>
              )}
              {DEPRECATED_PLANS.includes(plan) && (
                <Badge className="ml-2" color="pink">
                  {getText('billing.plan.legacy')}
                </Badge>
              )}
            </div>
            <div
              className={classNames('flex flex-col font-medium', {
                'my-4': !vertical,
                'ml-auto': vertical,
                'text-pink-800': plan === activePlan && surface !== DARK,
                'text-cyan-500': plan === activePlan && surface === DARK,
              })}
            >
              <div className="flex items-end">
                <span
                  className={classNames({
                    'text-4xl': !vertical,
                    'text-2xl': vertical,
                  })}
                >
                  {getText('billing.plans', plan, 'price.annual')}
                </span>
                {(plan !== FREE || vertical) && (
                  <div className="mb-1 ml-2 flex flex-col text-sm font-light text-gray-500">
                    {SEAT_BASED_PLANS.includes(plan) && (
                      <span>{getText('billing.perUser')}</span>
                    )}
                    <span>{getText('billing.perMonth')}</span>
                  </div>
                )}
              </div>
              {!vertical && (
                <span className="mt-2 text-sm text-gray-600">
                  {getText('billing.plans', plan, 'price.explainer')}
                </span>
              )}
            </div>
          </div>
          <div
            className={classNames('mb-4', {
              'font-medium': !vertical,
              'mt-4': vertical,
              'text-gray-200': surface === DARK,
            })}
          >
            {getText('billing.plans', plan, 'useCase')}
          </div>
          <div
            className={classNames(
              'flex flex-col space-y-1.5 text-sm font-light',
              {
                'text-pink-800': plan === activePlan && surface !== DARK,
                'text-cyan-500': plan === activePlan && surface === DARK,
              },
            )}
          >
            {FLAT_PLANS.includes(plan) && (
              <>
                <div className="flex items-center space-x-2">
                  <IconCircleCheck className="opacity-75" size={16} />
                  <span>
                    {getText('billing.plans', plan, 'features.projects')}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <IconCircleCheck className="opacity-75" size={16} />
                  <span>{getText('billing.plans', plan, 'features.team')}</span>
                </div>
              </>
            )}
            <div className="flex items-center space-x-2">
              <IconCircleCheck className="opacity-75" size={16} />
              <span>
                {getText('billing.plans', plan, 'features', 'limits')}
              </span>
            </div>
            {(FLAT_PLANS_V2.includes(plan) ||
              SEAT_BASED_PLANS.includes(plan)) && (
              <>
                {FLAT_PLANS_V2.includes(plan) && (
                  <>
                    <div className="flex items-center space-x-2">
                      <IconUsers className="opacity-75" size={16} />
                      <span className="whitespace-nowrap">
                        {getText('billing.plans', plan, 'features.teamSeats')}
                      </span>
                      <Badge m={{ l: 2, r: 0 }} className="whitespace-nowrap">
                        {getText(
                          'billing.plans',
                          plan,
                          'features.additionalTeamSeats',
                        )}
                      </Badge>
                    </div>
                    <div className="flex items-center space-x-2">
                      <IconUsers className="opacity-75" size={16} />
                      <span className="whitespace-nowrap">
                        {getText('billing.plans', plan, 'features.clientSeats')}
                      </span>
                      <Badge m={{ l: 2, r: 0 }} className="whitespace-nowrap">
                        {getText(
                          'billing.plans',
                          plan,
                          'features.additionalClientSeats',
                        )}
                      </Badge>
                    </div>
                    <div className="flex items-center space-x-2">
                      <IconDatabase className="opacity-75" size={16} />
                      <span>
                        {getText('billing.plans', plan, 'features.rows')}
                      </span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <IconDatabaseImport className="opacity-75" size={16} />
                      <span>
                        {getText('billing.plans', plan, 'features.syncedRows')}
                      </span>
                    </div>
                  </>
                )}
                <div className="flex items-center space-x-2">
                  <SyncingIcon
                    // @ts-expect-error TS(2322): Type '{ className: string; size: number; plan: str... Remove this comment to see the full error message
                    className="opacity-75"
                    size={16}
                    plan={plan}
                  />
                  <span>
                    {getText('billing.plans', plan, 'features.syncing')}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <IconBolt className="opacity-75" size={16} />
                  <span>
                    {getText('billing.plans', plan, 'features', 'workflows')}
                  </span>
                </div>
                {plan !== PRO && plan !== STARTER_V2 && (
                  <div className="flex items-center space-x-2">
                    <IconCirclePlus className="opacity-75" size={16} />
                    <span>
                      {getText('billing.plans', plan, 'features', 'rest')}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlanPicker;
